<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="numberOfPages"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="10"
        action-label="การกระทำ"
      >
        <template #cell(status)="{ item }">
          <!-- <pre>{{ item }}</pre> -->
          <div
            :class="{
              'text-success': item.status === 'ลงทะเบียนสำเร็จ',
              'text-danger': item.status === 'ไม่ผ่านการอนุมัติ',
            }"
          >
            {{ item.status }}
          </div>
        </template>

        <template #cell(approve)="{ item }">
          <!-- <pre>{{ item }}</pre> -->
          <div
            :class="{
              'text-success': item.approve === 'สำเร็จ',
              'text-danger': item.approve === 'ยังไม่สำเร็จ',
            }"
          >
            {{ item.approve }}
          </div>
        </template>

        <template #cell(actions)="{ item }">
          <!-- <pre>{{ item }}</pre> -->
          <div class="text-center">
            <b-button
              size="sm"
              :variant="getActionVariant(item)"
              @click="edit(item)"
              >{{ getActionText(item) }}</b-button
            >
          </div>
        </template>

        <template #cell(refresh)="{ item }">
          <div class="text-center">
            <b-button
              v-if="
                item.status !== 'ลงทะเบียนสำเร็จ' &&
                item.status !== 'ไม่ผ่านการอนุมัติ'
              "
              variant="danger"
              size="sm"
              @click="reject(item)"
              >ปฏิเสธ</b-button
            >
          </div>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
// import { calculateRetireYear } from "../../helpers/datepicker-helper";
import formatDateMixin from "../../mixins/formatDateMixin";
import { MRank, Registration } from "../../models";
import BaseTable from "./Base";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
    actionButtonText: {
      type: String,
      default: "แก้ไข",
    },
  },

  components: {
    BaseTable,
  },

  data() {
    return {
      isFetching: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      numberOfPages: 1,
      sortBy: "",
      sortDesc: false,
    };
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.refresh();
    },

    async fetch(ctx) {
      let data = [],
        additionalParams = {},
        numberOfPages = 1;

      if (!ctx.downloadAll) {
        this.$set(additionalParams, "limit", ctx.perPage);
        this.$set(
          additionalParams,
          "offset",
          (ctx.currentPage - 1) * ctx.perPage
        );
      }

      if (this.params && this.params.keyword) {
        this.$set(additionalParams, "keyword", this.params.keyword);
      }

      if (this.params && this.params.status !== null) {
        this.$set(additionalParams, "status", this.params.status);
      }

      if (
        this.params &&
        this.params.userType !== null &&
        this.params.userType == "ครูตำรวจแดร์"
      ) {
        this.$set(additionalParams, "hasUserType", 1);
      }

      if (
        this.params &&
        this.params.userType !== null &&
        this.params.userType == "ประชาชน"
      ) {
        this.$set(additionalParams, "hasUserType", 0);
      }

      if (this.params && this.params.startDate) {
        this.$set(additionalParams, "startDate", this.params.startDate);
      }

      if (this.params && this.params.endDate) {
        this.$set(additionalParams, "endDate", this.params.endDate);
      }

      if (this.sortBy) {
        this.$set(additionalParams, "order", this.sortBy);
        this.$set(additionalParams, "order_by", this.sortDesc ? "DESC" : "ASC");
      }

      this.isFetching = true;

      try {
        await MRank.api().findAll();

        const promise = await Registration.api().findAll(
          {
            ...additionalParams,
          },
          { save: false }
        );

        let { data: responseData = [], number_of_pages = 1 } =
          promise.response.data;

        data = responseData.map((record) => {
          let user_name = `${record.first_name} ${record.last_name}`;

          if (record.m_rank_id) {
            const mRank = MRank.find(record.m_rank_id);

            user_name = `${mRank.m_rank_name} ${user_name}`;
          }

          return {
            ...record,
            email: record.email || "-",
            approve: record.approve ? "สำเร็จ" : "ยังไม่สำเร็จ",
            // birth_date: record.birth_date || "-",
            phone_number: record.phone_number
              ? `${record.phone_number.substring(
                  0,
                  3
                )}-${record.phone_number.substring(
                  3,
                  6
                )}-${record.phone_number.substring(6, 10)}`
              : "-",
            user_type: record.user_profile_id ? "ครูตำรวจแดร์" : "ประชาชน",
            user_name,
            updatedAt: record.updatedAt
              ? this.$_formatDateMixin_formatShort(record.updatedAt)
              : "-",
            createdAt: record.createdAt
              ? this.$_formatDateMixin_formatShort(record.createdAt)
              : "-",
          };
        });

        numberOfPages = number_of_pages;
      } catch (error) {
        console.log(error);
        this.$toast.error(
          "ไม่สามารถดึงข้อมูลผู้ใช้งานได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      if (!ctx.downloadAll) {
        this.numberOfPages = numberOfPages;
      }

      return data;
    },

    getActionText(item) {
      let actionText = this.actionButtonText;

      if (item.status === "รออนุมัติ") {
        actionText = "อนุมัติ";
      }

      if (item.status === "รอการยืนยันการชำระเงิน") {
        actionText = "ยืนยัน";
      }

      return actionText;
    },

    getActionVariant(item) {
      let actionVariant = "";

      if (item.status === "รออนุมัติ") {
        actionVariant = "primary";
      }

      if (item.status === "รอการยืนยันการชำระเงิน") {
        actionVariant = "success";
      }

      return actionVariant;
    },

    edit(item) {
      this.$emit("edit", item);
    },

    reject(item) {
      this.$emit("reject", item);
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
