var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card__flex card card-box mb-3"},[_c('div',{staticClass:"card-header pr-2"},[_c('div',{staticClass:"card-header--title"},[_c('small',[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"card-header--actions"},[_vm._t("header-actions")],2)]),_c('div',{staticClass:"card-body p-0"},[_c('base-table',{ref:"table",attrs:{"provider":_vm.fetch,"fields":_vm.fields,"is-busy":_vm.isFetching,"current-page":_vm.currentPage,"number-of-pages":_vm.numberOfPages,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"per-page":10,"action-label":"การกระทำ"},on:{"update:isBusy":function($event){_vm.isFetching=$event},"update:is-busy":function($event){_vm.isFetching=$event},"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
            'text-success': item.status === 'ลงทะเบียนสำเร็จ',
            'text-danger': item.status === 'ไม่ผ่านการอนุมัติ',
          }},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"cell(approve)",fn:function(ref){
          var item = ref.item;
return [_c('div',{class:{
            'text-success': item.approve === 'สำเร็จ',
            'text-danger': item.approve === 'ยังไม่สำเร็จ',
          }},[_vm._v(" "+_vm._s(item.approve)+" ")])]}},{key:"cell(actions)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('b-button',{attrs:{"size":"sm","variant":_vm.getActionVariant(item)},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(_vm._s(_vm.getActionText(item)))])],1)]}},{key:"cell(refresh)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(
              item.status !== 'ลงทะเบียนสำเร็จ' &&
              item.status !== 'ไม่ผ่านการอนุมัติ'
            )?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.reject(item)}}},[_vm._v("ปฏิเสธ")]):_vm._e()],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }