<template>
  <b-modal
    v-model="$show"
    id="reject-modal"
    centered
    hide-header
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <h3 class="font-weight-bold">คุณต้องการปฏิเสธการสมัครสมาชิกนี้ใช่ไหม</h3>

    <div class="py-2 col-12">
      <label for="text-address">เหตุผลการปฏิเสธ</label>

      <b-form-textarea
        id="textarea"
        v-model="rejectCause"
        placeholder=""
        rows="3"
        max-rows="6"
        :state="invalid ? $v.rejectCause.required : null"
      ></b-form-textarea>

      <b-form-invalid-feedback id="text-last_name-feedback">
        กรุณากรอกเหตุผลการปฏิเสธ
      </b-form-invalid-feedback>
    </div>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>
      <b-button size="sm" variant="primary" @click="rejectItem">
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>ยืนยัน</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { Auth, Registration } from "../../models";

export default {
  props: {
    show: Boolean,

    rejectData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isSubmiting: false,
      invalid: false,
      rejectCause: "",
    };
  },

  validations: {
    rejectCause: { required },
  },

  model: {
    prop: "show",
    event: "update",
  },

  computed: {
    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },

    authUser() {
      return Auth.user();
    },
  },

  methods: {
    async rejectItem() {
      this.$v.$touch();

      this.invalid = false;

      if (this.$v.$invalid) {
        this.invalid = true;

        return this.$toast.error(`กรุณากรอกเหตุผลการปฏิเสธการสมัครสมาชิก`);
      }

      this.isSubmiting = true;
      try {
        await Registration.api().updateReject(this.rejectData.id, {
          reject_cause: this.rejectCause,
          reject_by: this.authUser.id,
        });

        // axios.post(
        //   `https://portal-otp.smsmkt.com/api/send-message`,
        //   {
        //     sender: "NSBPolice",
        //     phone: this.rejectData.phone_number.replace(/-/g, ""),
        //     message: `การสมัครสมาชิกชมรมแดร์ประเทศไทยหมายเลข: ${this.editData.registration_code} ไม่ได้รับการอนุมัติ ท่านสามารถตรวจสอบเหตุผลการปฏิเสธการสมัครสมาชิกได้ที่: <TRACKING-URL> ขอบคุณสำหรับการสมัครสมาชิกชมรมแดร์ประเทศไทย`,
        //     url: `${process.env.VUE_APP_FRONTEND_URL}/registers/detail?code=${this.editData.registration_code}`,
        //   },
        //   {
        //     headers: {
        //       api_key: 'b31fb4cb0403b694565b0279a8aa3983',
        //       secret_key: '8U13tP7rVfQrdwln',
        //     },
        //   }
        // );

        this.$toast.success("ปฏิเสธการสมัครสมาชิกสำเร็จ");

        this.$bvModal.hide("reject-modal");

        this.$emit("reject:success");
      } catch (error) {
        console.log(error);
        this.$toast.error("ปฏิเสธการสมัครสมาชิกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isSubmiting = false;
      }
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.$emit("update:rejectData", {});
    },
  },
};
</script>
