<template>
  <page-content>
    <page-title heading="การจัดการสมาชิกชมรม">
      <!-- <template v-slot:right>
        <b-button variant="danger" @click="onCreate">เพิ่มรายชื่อครู</b-button>
      </template> -->
      <template v-slot:right>
        <b-button variant="danger" @click="exportReport">
          <b-spinner v-if="isExporting" label="exporting"></b-spinner>
          <span v-else>ดาวน์โหลดรายงาน</span>
        </b-button>

        <export-modal v-model="isExporting" />
      </template>
    </page-title>

    <b-row>
      <!-- <b-col cols="12">
        <filter-master
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          :hiddenInput="{ school: true, schoolClass: true }"
        >
        </filter-master>
      </b-col> -->

      <b-col cols="12" class="mt-2 mb-2">
        <filter-date-between
          v-model="filterDateBetween"
          :col-start-date="3"
          :col-end-date="3"
        >
          <template #before>
            <b-col cols="12" lg="3" class="px-2">
              <label class="font-weight-bold ft-s-14" for="status">สถานะ</label>

              <v-select
                id="status"
                v-model="status"
                :clearable="status !== defaultValue"
                :options="registrationStatuses"
                @input="onInput($event, 'status')"
              ></v-select>
            </b-col>

            <b-col cols="12" lg="3" class="px-2">
              <label class="font-weight-bold ft-s-14" for="status"
                >ประเภทผู้สมัคร</label
              >

              <v-select
                id="user-type"
                v-model="userType"
                :clearable="userType !== defaultValue"
                :options="registrationUserType"
                @input="onInput($event, 'userType')"
              ></v-select>
            </b-col>

            <!-- <b-col cols="12" lg="3" class="px-2">
              <label class="font-weight-bold ft-s-14" for="approve"
                >ผลการสมัครสมาชิก</label
              >

              <v-select
                id="approve"
                v-model="approve"
                :clearable="approve !== defaultValue"
                :options="registrationApproves"
                @input="onInput($event, 'approve')"
              ></v-select>
            </b-col> -->
          </template>
        </filter-date-between>
      </b-col>

      <b-col sm="8" md="6" class="pr-sm-0">
        <b-form-group class="px-2">
          <b-input-group class="input-group-seamless">
            <b-form-input
              placeholder="ค้นหารายชื่อผู้สมัครสมาชิก (ชื่อ-สกุล, เบอร์โทรศัพท์, เลขบัตรประชาชน หรือ หมายเลขการสมัครสมาชิก)"
              v-model="keyword"
              @keyup.enter="onKeywordSearched"
            ></b-form-input>

            <b-input-group-text slot="prepend">
              <font-awesome-icon class="mx-auto" icon="search" />
            </b-input-group-text>

            <b-input-group-text v-show="keyword.length" slot="append">
              <a href="javascript:void(0)" @click="clearKeyword">
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="4" md="2" class="px-sm-2 px-4">
        <b-button variant="primary" block size="sm" @click="onKeywordSearched"
          >ค้นหา</b-button
        >
      </b-col>
    </b-row>

    <registration-table
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="registrationTableParams"
      @edit="onEdit"
      @reject="onReject"
    />

    <create-or-update-registration
      :edit-data.sync="editData"
      v-model="showModal"
      @create:success="onCreateSuccess"
    ></create-or-update-registration>

    <reject-registration
      v-model="showModalReject"
      @reject:success="onRejectSuccess"
      :reject-data.sync="rejectData"
    ></reject-registration>
  </page-content>
</template>

<script>
import vSelect from "vue-select";
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateRegistration from "../../components/modal/CreateOrUpdateRegistration";
import RegistrationTable from "../../components/table/Registration";
import FilterDateBetween from "../../components/form/FilterDateBetween";
import RejectRegistration from "../../components/modal/RejectRegistration";
import { Auth, Registration } from "../../models";
import ExportModal from "../../components/modal/Export";
import reportMixin from "../../mixins/reportMixin";
import { saveAs } from "file-saver";
import XLSX from "xlsx";

const defaultValue = "ทั้งหมด";

export default {
  mixins: [reportMixin],

  components: {
    PageContent,
    PageTitle,
    CreateOrUpdateRegistration,
    RegistrationTable,
    RejectRegistration,
    vSelect,
    FilterDateBetween,
    ExportModal,
  },

  data() {
    return {
      keyword: "",
      formfilter: {},
      filterDateBetween: {
        startDate: null,
        endDate: null,
      },
      editData: {},
      rejectData: {},
      showModal: false,
      showModalReject: false,
      defaultValue,
      // approve: defaultValue,
      // registrationApproves: [defaultValue, "สำเร็จ", "ยังไม่สำเร็จ"],
      status: defaultValue,
      userType: defaultValue,
      registrationStatuses: [
        defaultValue,
        "รออนุมัติ",
        "รอชำระเงิน",
        "รอการยืนยันการชำระเงิน",
        "ลงทะเบียนสำเร็จ",
        "ไม่ผ่านการอนุมัติ",
      ],
      registrationUserType: [defaultValue, "ครูตำรวจแดร์", "ประชาชน"],
      fields: [
        // { key: "order", label: "ลำดับ" },
        // { key: "rank", label: "ยศ", sortable: true },
        // { key: "first_name", label: "ชื่อ", sortable: true },
        // { key: "last_name", label: "สกุล", sortable: true },
        {
          key: "registration_code",
          label: "หมายเลขการสมัครสมาชิก",
          sortable: true,
        },
        { key: "user_name", label: "ชื่อ-สกุล", sortable: true },
        { key: "phone_number", label: "เบอร์โทรศัพท์", sortable: true },
        // { key: "bd_date", label: "วัน/เดือน/ปีเกิด", sortable: true },
        // { key: "email", label: "อีเมล" },
        // { key: "retire_year", label: "ปีเกษียณ", sortable: true },
        // { key: "medal", label: "ระดับเข็ม", sortable: true },
        { key: "user_type", label: "ประเภทผู้สมัคร", sortable: true },
        { key: "status", label: "สถานะ", sortable: true },
        { key: "approve", label: "ผลการสมัครสมาชิก", sortable: true },
        {
          key: "createdAt",
          label: "วันที่สร้าง",
          sortable: true,
        },
        {
          key: "updatedAt",
          label: "อัพเดตล่าสุด",
          sortable: true,
        },
      ],
      exportFields: {
        หมายเลขการสมัครสมาชิก: "registration_code",
        "ชื่อ-สกุล": "user_name",
        เบอร์โทรศัพท์: "phone_number",
        ประเภทผู้สมัคร: "user_type",
        สถานะ: "status",
        ผลการสมัครสมาชิก: "approve",
        วันที่สร้าง: "createdAt",
        อัพเดตล่าสุด: "updatedAt",
      },
      isExporting: false,
    };
  },

  // watch: {
  //   formfilter: {
  //     deep: true,
  //     handler: "onFormFilterChanged",
  //   },
  // },

  computed: {
    authUser() {
      return Auth.user();
    },

    registrationTableParams() {
      const {
        formfilter,
        filterDateBetween,
        // approve: inputApprove,
        status: inputStatus,
        userType: inputUserType,
      } = this;

      // let approve = null;

      let status = null;
      let userType = null;

      // if (inputApprove !== defaultValue) {
      //   if (inputApprove === "ยังไม่สำเร็จ") {
      //     approve = false;
      //   }

      //   if (inputApprove === "สำเร็จ") {
      //     approve = true;
      //   }
      // }

      if (inputStatus !== defaultValue) {
        status = inputStatus;
      }

      if (inputUserType !== defaultValue) {
        userType = inputUserType;
      }

      return {
        ...formfilter,
        ...filterDateBetween,
        // approve,
        status,
        userType,
      };
    },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    async fetchExport() {
      let data = [];

      this.isExporting = true;

      try {
        data = await this.$refs.table.fetch({ downloadAll: true });
      } catch (error) {
        this.$toast.error("ไม่สามารถดึงข้อมูลรายงานได้ กรุณาลองใหม่ภายหลัง");
      } finally {
        this.isExporting = false;
      }

      return data;
    },

    exportOnePage(data) {
      let exportFields = { ...this.exportFields };

      let header = Object.keys(exportFields);

      const wb = XLSX.utils.book_new();

      let sheetHeader = ["ลำดับ", ...header];

      data = data.map((record, index) => {
        const res = {
          ลำดับ: index + 1,
        };

        header.forEach((col) => {
          res[col] = record[exportFields[col]];
        });

        return res;
      });

      let ws = XLSX.utils.json_to_sheet(data, {
        header: sheetHeader,
      });

      ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
        orderCol: "A",
      });

      ws = this.$_reportMixin_setWidthColumn(ws, sheetHeader, data);

      XLSX.utils.book_append_sheet(wb, ws, "รายชื่อสมาชิกชมรมแดร์");

      return wb;
    },

    async exportReport() {
      let data = await this.fetchExport();

      if (data && data.length) {
        const wb = this.exportOnePage(data);

        /* bookType can be any supported output type */
        const wopts = { bookType: "xlsx", bookSST: false, type: "array" };

        const wbout = XLSX.write(wb, wopts);

        /* the saveAs call downloads a file on the local machine */
        saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "รายชื่อสมาชิกชมรมแดร์.xlsx"
        );
      }
    },

    // onFormFilterChanged(formFilter) {
    //   const query = {};
    //   const { authUser } = this;

    //   if (!authUser.mHeadquarterId && formFilter.mHeadquarterId) {
    //     this.$set(query, "mHeadquarterId", formFilter.mHeadquarterId);
    //   }

    //   if (!authUser.mDivisionId && formFilter.mDivisionId) {
    //     this.$set(query, "mDivisionId", formFilter.mDivisionId);
    //   }

    //   if (!authUser.mStationId && formFilter.mStationId) {
    //     this.$set(query, "mStationId", formFilter.mStationId);
    //   }

    //   console.log(query, formFilter);
    //   if (Object.keys(query).length) {
    //     this.$router.replace({
    //       name: "MasterDataTeacher",
    //       query,
    //     });
    //   }
    // },

    onCreate() {
      this.showModal = true;
    },

    async onEdit(item) {
      await Registration.api().findOne(item.id);

      const registration = Registration.find(item.id);

      this.editData = { ...registration };

      this.showModal = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onReject(item) {
      this.rejectData = { ...item };
      this.showModalReject = true;
    },

    onRejectSuccess() {
      this.$refs.table.refresh();
    },

    onInput(value, dataName) {
      if (!value) {
        this.$set(this, dataName, this.defaultValue);
      }
    },
  },
};
</script>
